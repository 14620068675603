//邮箱
export const validateEmail = (rule, value, callback) => {
  // let mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  let mal = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;

  if (value && !mal.test(value)) {
    callback(new Error("请输入正确邮箱"));
  } else {
    callback();
  }
};
// 手机号验证
export const validateMobile = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("请输入手机号"));
  } else if (!/^((0\d{2,3}-\d{7,8})|(^1[3-9]\d{9}))$/.test(value)) {
    callback("手机号格式不正确");
  } else {
    callback();
  }
};
// 身份证验证
export const idcard = (rule, value, callback) => {
  let regFormat = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/; //正确身份证
  if (!value) {
    return callback(new Error("请输入身份证号"));
  } else if (value && !regFormat.test(value)) {
    callback(new Error("请输入有效身份证号"));
  } else {
    return callback()
  }
};
